#root {
  background: #f5f7fa;
  overflow: hidden;
  min-height: 100vh;
}

.flex-row {
  flex-direction: row !important;
}

.login-form .logo {
  width: 160px;
  margin-top: 2rem;
}

.card {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.6);
}

.ant-col.card {
  padding: 0;
}

.gap-1 {
  gap: 1rem;
}

.pp-main-button {
  border-radius: 5px !important;
  background-color: #3f81f4 !important;
  padding: 10px 15px !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  height: 42px !important;
  min-width: 100px;
}

.ant-table-cell {
  color: #454545;
  font-weight: 600;
}

.text-dim {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-white {
  color: white !important;
}

.flex-100 {
  flex: 1;
}

.user-col {
  min-width: 150px;
}

.user-col-lg {
  min-width: 200px;
}

.ant-table-container {
  overflow: auto;
}

.right-alinged-modal {
  float: right;
  top: 0px !important;
  width: 35rem !important;
  min-height: 100vh;
  padding-bottom: 0;
}

.right-alinged-modal .ant-modal-content {
  min-height: 100vh;
}

.ant-checkbox-checked {
  height: 1rem;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
} */
.ant-checkbox-checked::after {
  display: none;
}

.ant-checkbox-checked::after {
  border: none;
}

.error {
  color: red;
  margin-top: 5px;
}

.star::before {
  content: "* ";
  color: red;
}

/* 
.grid {
  display: grid;
  max-width: 100%;
  grid-gap: 1.6rem;
  margin: 0 1rem;
}

.main-area {
  grid-template-areas:
    "header header header"
    "nav main_content main_content"
    "footer footer footer";
}

.g-header {
  grid-area: header;
  width: 97%;
  position: fixed;
  z-index: 1000;
  background: #f5f7fa;
}

.g-footer {
  grid-area: footer;
}

.g-nav {
  grid-area: nav;
  position: fixed;
  top: 9.4rem;
}

.g-content {
  grid-area: main_content;
  width: 85%;
  position: relative;
  left: 13%;
  top: 10%;
  margin-bottom: 6rem;
} */
.approve,
.reject,
.delete,
.cancel {
  min-width: 8rem;
  padding: 0 1rem;
  border-radius: 10px;
  font-weight: 500;
  height: 42px;
}

.approve {
  width: 100px;
  background: #48b553 0 0 no-repeat padding-box;
}

.reject {
  background: #efa42c 0 0 no-repeat padding-box;
  color: #fff
}

.delete {
  background: #ff4d4f 0 0 no-repeat padding-box;
  color: #fff;
}

.cancel {
  background: #fff;
  color: #1890ff !important;
  border: 1px solid #1890ff;
}

.ant-input[disabled],
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #f5f5f5 !important;
  cursor: not-allowed !important;
}